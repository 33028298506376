import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    appHeader: {
        position: 'fixed',
        top: 0,
        right: 0,
        left: 'auto',
        height: '30px',
        overflow: 'hidden'
    },
    myName: {
        fontSize: '25px',
        fontWeight: '700'
    }
}));

const Header = () => {

    const classes = useStyles();

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" className={classes.title}>
                    Sweetcola
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

export default Header;