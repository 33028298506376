import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {ArrowBackIos, ArrowForwardIos} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    wrap: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    maskLayer: {
        zIndex: '1000',
        transition: 'opacity .3s ease-in-out',
        opacity: '0',
        width: '100%',
        height: '100%',
        position: 'fixed',
        left: '0',
        top: '0',
        background: 'rgba(0,0,0, 0.7)'
    },
    show: {
        opacity: '1'
    },
    pre: {
        left: '0',
        background: 'linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, .6))'
    },
    next: {
        right: '0',
        background: 'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, .6))'
    },
    switchBtn: {
        top: '0',
        width: '10%',
        height: '100%',
        position: 'fixed',
        opacity: '0',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            opacity: '1'
        }
    },
    switchBtnIcon: {
        color: '#fff',
        fontSize: '5rem'
    },
    layerImage: {
        cursor: 'zoom-out',
        maxWidth: '100%',
        maxHeight: '100%'
    }
}));

const MaskLayer = ({hide, srcList, index}) => {

    const classes = useStyles();

    let [show, setShow] = useState(false);
    let [curIndex, setCurIndex] = useState(index);

    useEffect(() => {
        setTimeout(() => setShow(true), 0);
    }, []);

    const hideLayer = () => {
        setTimeout(() => {
            setShow(false);
            setTimeout(() => hide(), 300);
        }, 0);
    };

    const handleBtnClick = (flag) => {
        if (flag && curIndex < srcList.length - 1) setCurIndex(++curIndex);
        else if (!flag && curIndex > 0) setCurIndex(--curIndex);
    };

    return (
        <div
            className={`${classes.maskLayer} ${show? classes.show: ''}`}
        >
            <div className={classes.wrap} onClick={hideLayer}>
                <img
                    className={classes.layerImage}
                    src={srcList[curIndex]}
                    alt='img'
                />
            </div>
            <div
                className={`${classes.switchBtn} ${classes.pre}`}
                onClick={() => handleBtnClick(false)}
            >
                <ArrowBackIos className={classes.switchBtnIcon} />
            </div>
            <div
                className={`${classes.switchBtn} ${classes.next}`}
                onClick={() => handleBtnClick(true)}
            >
                <ArrowForwardIos className={classes.switchBtnIcon} />
            </div>
        </div>
    );
};

export default MaskLayer;