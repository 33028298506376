import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Header from '../../components/Header';
import ProjectSection from '../../components/ProjectSection';
import { projectData } from '../../constants/projectData';

const useStyles = makeStyles(theme => ({
    content: {
        margin: '0 auto'
    },
    sectionTitle: {
        margin: '0.875rem 0'
    }
}));

const Home = () => {

    const classes = useStyles();

    return (
        <React.Fragment>
            <Header />
            <Container className={classes.content} maxWidth='md'>
                <Typography className={classes.sectionTitle} variant='h4'>
                    Projects
                </Typography>
                {projectData.map((item, index) => (
                    <ProjectSection projectData={item} key={index} />
                ))}
            </Container>
        </React.Fragment>
    );
};

export default Home;