import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import SectionDetail from './SectionDetails';

const useStyles = makeStyles(theme => ({
    section: {
        width: '100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(20)
    }
}));

const ProjectSection = ({projectData}) => {

    const classes = useStyles();

    return (
        <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
            >
                <Typography className={classes.heading}>
                    {projectData.title}
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <SectionDetail data={projectData} />
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};

export default ProjectSection;