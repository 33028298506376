const projectData = [
    {
        title: 'ScTranslator',
        dateStart: '2020/01',
        dateEnd: 'now',
        languageAndTool: 'JavaScript, React, React-Redux, VS Code',
        description: `该项目是一个 Chrome 的翻译扩展。开发该项目是为了提供一个更好的网页划词翻译体验。` +
        `\n\n该扩展提供了多种方式来对应网页内的划词翻译，用户可以根据自己的喜好来关闭或开启。\n` +
        `在该项目中，为了对扩展进行国际化使用到 i18n，目前支持英文、繁中、简中和日文。\n` +
        `用户们都有自己所认为的最好看的颜色搭配，因此该扩展在提供了亮色主题和黑夜主题的前提下，提供了主题自定义的功能。\n` +
        `用户会有需要在 PDF 文件中使用翻译功能的需求，虽然浏览器通常会提供 PDF 文件的打开方式，但扩展并不能在这种页面中使用。` +
        `为了完成这一需求，该扩展会拦截所有 PDF 文件的请求，在拦截后跳转到该扩展所自带的 PDF 阅读器。PDF 阅读器使用的是 PDF.js 。\n` +
        `该扩展提供翻译记录的功能，当用户在单个页面中进行翻译，用户可以在左边弹出栏里找到曾经的翻译结果。\n` +
        `该扩展准备了丰富的快捷键供用户选择，配合快捷键可以让翻译的体验得到进一步的提升。\n` +
        `在电脑的使用过程中，难免会有想在浏览器没有打开的情况下或者想要不通过浏览器而直接使用翻译软件的情况。` +
        `该扩展通过浏览器来创建独立窗口来展示翻译扩展的内容，从而完成这一需求。` +
        `\n\n在这次开发过程中，我学习了非常多关于Chrome浏览器的相关知识，开源` +
        `并发布了“google-translate-result”、“bing-translate-result”这两个翻译 API。目前该插件` +
        `已在 Chrome 商店、 Edge 商店和 Firefox 商店上发布。`,
        link: [
            'https://chrome.google.com/webstore/detail/sctranslator/icfnljfpacimpcbpammmbclmhenimhfc',
            'https://microsoftedge.microsoft.com/addons/detail/ebkimaahhkeiplegpghijhgmlcdkeppf',
            'https://addons.mozilla.org/zh-CN/firefox/addon/sctranslator/',
            'https://github.com/chunibyocola/sc-translator-crx',
            'https://github.com/chunibyocola/google-translate-result',
            'https://www.npmjs.com/package/google-translate-result'
        ],
        imgSrcArr: [
            '/static/pScTranslator/1.png', '/static/pScTranslator/2.png', '/static/pScTranslator/3.png', '/static/pScTranslator/4.png'
        ]
    },
    {
        title: 'Web Game 2048',
        dateStart: '2019/11',
        dateEnd: '2019/11',
        languageAndTool: 'JavaScript, VS Code',
        description: `该项目为模仿几年前比较流行的益智网页小游戏。\n\n` +
        `原项目为使用Div标签进行移动的方式进行开发，我也曾在2018年6月的时候使用` +
        `这种方式成功开发过，这次我选择使用Js + Canvas的方式进行开发。` +
        `使用requestAnimationFrame的方式来分割代码运行和画面绘制，使帧率达到` +
        `60FPS。我通过使用高可用的设计思想来开发，可以通过各种配置来进行游戏，` +
        `比如可以让游戏的列数由4变为100、一次生成多少个子项目等等。\n\n` + 
        `在开发完毕后的测试发现，如果在游戏未结束的情况下更新游戏配置会导致在游戏` +
        `结束后展示更新前的界面。在检查代码逻辑没有错误后推断为GC未能清理之前的内存` +
        `，也就是说还存在引用，在进一步查找未处理引用后得出结论：循环绘制的update` +
        `函数并没有被停止。那么，在更新前执行游戏的停止函数即可解决。`,
        display: 'https://game.sweetcola.top/2048.html',
        link: ['https://game.sweetcola.top/2048.html']
    },
    {
        title: 'Web Game Dino',
        dateStart: '2019/10',
        dateEnd: '2019/10',
        languageAndTool: 'JavaScript, VS Code',
        description: `该项目模仿断网时打开谷歌浏览器时的彩蛋游戏。` +
        `开发这个项目的主要目的是通过编码来学习Web API中的requestAnimationFrame以及` +
        `HTML5的Canvas。\n\n本项目中，我采用与原项目不同的方式来实现。在原项目` +
        `中，通过图片来进行展示并用碰撞Box来计算碰撞面积，用这种方式开发快捷` +
        `并且灵活，但缺点是不能精准计算碰撞。我采用像素数据的方式来开发，通过这种` +
        `方式可以精准计算碰撞，但副作用也是明显的，会造成计算量大大增加、开发难度` +
        `增加等。\n\n本项目中，涉及到体积碰撞计算、高度计算、速度计算、调度中心、` +
        `游戏运作逻辑等方面的知识。\n\nP.S. 这是我第四次写这个项目，先前分别于2016` +
        `年6月用Qt、于2016年11月用C#、2018年6月用Android写过并在其基础上进行了` +
        `玩法上的创新，各撰写了一份报告。`,
        display: 'https://game.sweetcola.top/dino.html',
        link: ['https://game.sweetcola.top/dino.html']
    },
    {
        title: '基于智能推荐的分期购物平台',
        dateStart: '2018/12',
        dateEnd: '2019/05',
        languageAndTool: 'JavaScript, React, WebSocket, C#, 高并发IOCP模型, VS Code,' +
        'VS2017, MySql, Xshell 6, Xftp 6',
        description: `该项目为毕业设计项目，是两人共同合作完成的，采用` +
        `前后分端的方式进行开发，我负责的部分为前端部分以及整个客服模块的开发。` +
        `\n\n本项目是一个提供给用户浏览、购买商品的电商平台。具有很多功能如：登陆、` +
        `注册、购物车、搜索、付款、客服等等功能。项目采用前后分端的方式进行开发，` +
        `React APP通过webpack打包后发布到Nginx反向代理服务器上。客服功能通过` +
        `WebSocket来进行数据交互，用户在已登陆的情况下可以在任何页面使用，在刷新页面后` +
        `可以重新连接到服务器、可以多页面同时与客服人员进行交互。WebSocket服务器使用` +
        `的是C#编写的IOCP服务器，具有高效、高并发的优势，以控制台的方式呈现，是一个` +
        `从WebSocket握手开始搭建的服务器。\n\n撰写论文一份。`,
        link: ['http://mall.sweetcola.top'],
        imgSrcArr: [
            '/static/pMall/1.jpg', '/static/pMall/2.jpg', '/static/pMall/3.jpg',
            '/static/pMall/4.png', '/static/pMall/5.jpg', '/static/pMall/6.jpg',
            '/static/pMall/7.jpg', '/static/pMall/8.jpg'
        ]
    },
    {
        title: '"醋醋"电商网站',
        dateStart: '2018/07',
        dateEnd: '2018/07',
        languageAndTool: 'JavaScript, Ajax短轮询, Spring Boot, Git, VS Code' +
        ', IntelliJ IDEA',
        description: `该项目为暑期实训项目，项目小组中共有五人，我所负责的是网站` +
        `后台系统的开发以及客服模块的开发。\n\n用户使用网站进行购物，网站管理员` + 
        `管理商品、用户、订单、标签。用户可以使用客服模块来与客服人员进行交互` + 
        `客服模块使用短轮询模拟心跳包来判断用户或工作人员是否短线，一旦前端判断断线` +
        `后会进行重连。\n\n撰写需求分析、系统实现、报告各一份。`,
        link: ['https://gitee.com/complone/mallSystem']
    },
    {
        title: '基于WebSocket的在线交互平台',
        dateStart: '2018/03',
        dateEnd: '2018/06',
        languageAndTool: 'Java, C#, JavaScript, 高并发IOCP模型, WebSocket, ' +
        'Media Source Extension, Ajax, Cookie, Spring MVC, Hibernate, IntelliJ IDEA' +
        ', VS2015',
        description: `该项目为J2EE课程设计项目，本人独立完成项目的所有内容。\n\n` + 
        `该项目开发的是一个可以让用户在网页上通过WebSocket的方式进行在线信息交互` +
        `的网站。用户可以在聊天过程中发送文件、图片、WEBM格式的视频。当用户发送` + 
        `完视频后，接收方可以点击视频在线播放，通过获取从WebSocket发送的存放在服务器` +
        `的WEBM视频的文件碎片后推入Media Source Extension对象来实现。\n\n撰写报告一份。`,
        imgSrcArr: [
            '/static/pChat/1.jpg', '/static/pChat/2.jpg', '/static/pChat/3.jpg',
            '/static/pChat/4.png'
        ]
    },
    {
        title: '基于TCP的远程控制软件',
        dateStart: '2017/12',
        dateEnd: '2017/12',
        languageAndTool: 'Java, IntelliJ IDEA',
        description: `该项目为Java课程设计项目，本人独立完成项目的所有内容。\n\n` + 
        `该项目通过一台局域网内的电脑控制另一台局域网中的电脑，具体控制包括键盘、` +
        `鼠标的一切操作。项目中使用到多方面的知识如：JavaGUI、Window API、Robot、` +
        `图像流传输、多线程、Sokcet通信等。连接方通过输入被连接方的局域网IP后点击` +
        `连接后即可控制被连接方的电脑。\n\n撰写报告一份。`,
        imgSrcArr: [
            '/static/pTelecontrol/1.jpg', '/static/pTelecontrol/2.jpg'
        ]
    },
    {
        title: '基于Ajax短轮询的具有实时信息交互功能的班级考勤系统',
        dateStart: '2017/9',
        dateEnd: '2017/12',
        languageAndTool: 'JavaScript, ASP.NET, Ajax短轮询, VS2015',
        description: `该项目为ASP.NET实训及课程设计项目，本人独立完成项目的所有内容。` +
        `\n\n该项目用于记录学生的考勤情况，以班级课程为单位，记录每一节课的考勤信息。` + 
        `该系统具有以下功能：用户登陆、更改密码、上传Excel文件导入到数据库、添加数据、` +
        `删除数据、更改数据、打印各类考勤相关信息、班级负责人之间的交流等。\n\n` +
        `撰写报告一份。`,
        imgSrcArr: [
            '/static/pAttendance/1.png', '/static/pAttendance/2.jpg',
            '/static/pAttendance/3.jpg'
        ]
    },
    {
        title: '基于TCP通讯的五子棋对战平台',
        dateStart: '2016/12',
        dateEnd: '2016/12',
        languageAndTool: 'C#, VS2012',
        description: `该项目为C#实训项目，本人独立完成项目的所有内容。\n\n` +
        `该项目可分为两部分，服务端与客户端。服务器开启服务后，客户端通过填写服务器的` +
        `IP地址来进行连接。在客户端连接成功后进入游戏大厅，通过点击房间号进入房间，` +
        `当房间中的两方都准备后五子棋对战开始。对战中的数据交换通过服务器中转，对战` +
        `数据保存在服务器内存中，\n\n撰写报告一份。`,
        imgSrcArr: [
            '/static/pBattleZone/1.jpg', '/static/pBattleZone/2.jpg',
            '/static/pBattleZone/3.jpg', '/static/pBattleZone/4.jpg',
            '/static/pBattleZone/5.jpg'
        ]
    },
    {
        title: '基于Qt的自创打地鼠游戏',
        dateStart: '2016/3',
        dateEnd: '2016/6',
        languageAndTool: 'C++, Qt, QtCreator 5.6',
        description: `该项目为C++实训项目，本人独自完成项目的所有内容。\n\n` +
        `该项目为自己根据自己想法做出来的一个小游戏，有许多功能如：故事前提、` +
        `游戏说明、难度选择、游戏暂停、音量选择、歌曲切换等。\n\n撰写报告一份。`,
        imgSrcArr: [
            '/static/pMouse/1.jpg', '/static/pMouse/2.jpg', '/static/pMouse/3.jpg',
            '/static/pMouse/4.jpg', '/static/pMouse/5.jpg'
        ]
    },
    {
        title: '基于C++的控制台2048',
        dateStart: '2016/3',
        dateEnd: '2016/3',
        languageAndTool: 'C++, VS2010',
        description: `该项目为C++实训项目，本人独自完成项目的所有内容。\n\n` +
        `该项目为对当时流行的游戏“2048”进行模仿实现，并在保持主要游戏效果一致` +
        `的前提下加入分数记录排行榜的功能。\n\n撰写报告一份。`,
        imgSrcArr: [
            '/static/p2048/1.png', '/static/p2048/2.png', '/static/p2048/3.png'
        ]
    }
];

export { projectData } ;