import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaskLayer from './MaskLayer';

const useStyles = makeStyles(theme => ({
    wrap: {
        overflowX: 'auto',
        backgroundColor: '#eee',
    },
    imageBox: {
        whiteSpace: 'nowrap'
    },
    item: {
        width: '100px',
        height: '100px',
        overflow: 'hidden',
        margin: '5px',
        boxSizing: 'border-box',
        display: 'inline-block',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px #fff solid',
        padding: '3px',
        cursor: 'zoom-in',
        textAlign: 'center',
        '&:hover': {
            borderColor: '#ddd'
        }
    },
    image: {
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%'
    },
    layerImage: {
        cursor: 'zoom-out',
        maxWidth: '100%',
        maxHeight: '100%'
    }
}));

const ImageBox = ({srcList}) => {

    const classes = useStyles();

    let [showMaskLayer, setShowMaskLayer] = useState(false);
    let [currentSrc, setCurrentSrc] = useState('');

    return (
        <div className={classes.wrap}>
            <div className={classes.imageBox}>
                {srcList.map((item, index) => (
                    <div
                        onClick={() => {
                            setShowMaskLayer(true);
                            setCurrentSrc(index);
                        }}
                        className={classes.item}
                        key={index}
                    >
                        <div
                            className={classes.image}
                            style={{backgroundImage: `url(${item})`}}
                            alt='img'
                        />
                    </div>
                ))}
                {showMaskLayer?
                    <MaskLayer
                        hide={() => setShowMaskLayer(false)}
                        srcList={srcList}
                        index={currentSrc}
                    />: ''}
            </div>
        </div>
    );
};

export default ImageBox;