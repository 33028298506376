import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ImageBox from '../ImageBox';

const useStyles = makeStyles(theme => ({
    detail: {
        width: '100%'
    },
    color1976d2: {
        color: '#1976d2'
    },
    description: {
        width: '100%',
        wordBreak: 'break-word',
        whiteSpace: 'pre-line'
    },
    display: {
        boxSizing: 'border-box',
        height: '200px',
        width: '100%'
    },
    displayWrap: {
        width: '100%',
        overflow: 'hidden'
    },
    breakWord: {
        wordBreak: 'break-word'
    }
}));

const SectionDetail = ({data}) => {

    const classes = useStyles();

    return (
        <div className={classes.detail}>
            <Typography className={classes.color1976d2} variant='subtitle2'>
                Date
            </Typography>
            <Typography variant='body2'>
                {`${data.dateStart} - ${data.dateEnd}`}
            </Typography>
            <hr />
            <Typography className={classes.color1976d2} variant='subtitle2'>
                Language & Tool
            </Typography>
            <Typography variant='body2'>
                {data.languageAndTool}
            </Typography>
            <hr />
            <Typography className={classes.color1976d2} variant='subtitle2'>
                Description
            </Typography>
            <pre className={classes.description}>
                {data.description}
            </pre>
            {
                data.link?
                    (<React.Fragment>
                        <hr />
                        <Typography className={classes.color1976d2} variant='subtitle2'>
                            Link
                        </Typography>
                        {data.link.map((item, index) => (
                            <Typography key={index} className={classes.breakWord}>
                                <a href={item}>{item}</a>
                            </Typography>
                        ))}
                    </React.Fragment>)
                    : ''
            }
            {
                data.imgSrcArr && data.imgSrcArr.length !== 0?
                    (<React.Fragment>
                        <hr />
                        <Typography className={classes.color1976d2} variant='subtitle2' gutterBottom>
                            Image Show
                        </Typography>
                        <ImageBox srcList={data.imgSrcArr} />
                    </React.Fragment>)
                    : ''
            }
            {
                data.display?
                    (<React.Fragment>
                        <hr />
                        <Typography className={classes.color1976d2} variant='subtitle2' gutterBottom>
                            Display
                        </Typography>
                        <div className={classes.displayWrap}>
                            <iframe
                                title='display'
                                className={classes.display}
                                src={data.display}
                            />
                        </div>
                    </React.Fragment>)
                    : ''
            }
        </div>
    );
};

export default SectionDetail;